import pic1 from "../../assets/images/project_img_1.jpeg";
import pic2 from "../../assets/images/project_img_2.jpeg";
import pic3 from "../../assets/images/project_img_3.jpeg";
import pic4 from "../../assets/images/project_img_4.jpeg";
import pic5 from "../../assets/images/project_img_5.jpeg";
import pic6 from "../../assets/images/project_img_6.jpeg";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import './project.scss';
const Project = () => {
    return (
        <>
            <Navbar />
            <div class = "project common-padding">
                <div class = "heading"> Projects </div>
                <div class = "main d-flex flex-column align-items-center">
                    <img class="img" src={pic1} alt='architect' />
                    <img class="img" src={pic2} alt='architecture'/>
                    <img class="img" src={pic3} alt='architectural design'/>
                    <img class="img" src={pic4} alt='commercial architects near me'/>
                    <img class="img" src={pic5} alt='frank lloyd wright' />
                    <img class="img" src={pic6} alt='home architects near me'/>
                    <div class="more-content">
                        And Many More...
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Project;