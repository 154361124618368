import contactBG from '../../assets/images/ContactUs.svg';
import blackPhone from '../../assets/icons/phone_black_18dp.svg'
import whitePhone from '../../assets/icons/phone_white_18dp.svg'

import './contact.scss'
const Contact = () => {
    return (
        <div className='d-flex justify-content-center align-items-start contact-us common-padding'>
            <div className='max-width-50'>
                <h1 className='heading'>CONTACT US</h1>
                <div className='text'> We'd love to hear from you! Please reach out at your convenience so we can discuss your project.</div>
                <div className='action d-flex'>
                    <a href="tel:9584695529" className='call-button call-button-yellow'><img className='phone' src={blackPhone} alt="architects near me now" /> <span> +91-9584695529</span></a>
                    <a href="tel:9981995529" className='call-button call-button-black'><img className='phone' src={whitePhone} alt="architects near me now" /> <span> +91-9981995529</span></a>
                </div>

            </div>
            <img className='max-width-50' src={contactBG} alt="architects around me" />
        </div>
    )
}
export default Contact;