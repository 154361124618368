import Contact from "../../components/contact/contact";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";

const ContactUs = () => {
    return (
        <div>
            <Navbar />
            <Contact />
            <Footer />
        </div>
    )
}
export default ContactUs;