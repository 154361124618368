import pic from '../../assets/images/shivam-bhai-profile.jpeg';
import './aboutMe.scss'
const AboutMe = () => {
    return <div className='about-me common-padding common-margin-top'>
        <h2 className='common-heading mb-5'>About Me</h2>
        <div className='d-flex box flex-column-reverse flex-md-row'>
            <div className='content'>
                <div className='text'>
                    Our team is a Bhopal-based architectural firm. Founded by <span className='bolder'>AR.SHIVAM CHOUKSEY</span> , who believes in tradition and conserving the essence of a place, and building a sustainable life. All projects incorporate a unique identity and functional solutions. The firm’s big picture is to establish designs that can contribute to humankind and inexorably, the earth.
                </div>
                <div>
                    <h3 className='sub-head mt-4'>Design Philosophy</h3>
                    <div className='text bottom-text'>
                        The Heera architects believe in pragmatic and free-flowing design. The uniqueness and the concept of sustainability is the prior approach towards designing the spaces. We process with an ideology to serve people and the environment. The core of the design leads through the best level of functioning, engineering, and quality of details. Yes, The firm worships detail and the client's comfort. <br /> we craft the emotions of an individual and deliver the key to the remarkable design.
                    </div>

                </div>
            </div>
            <div>
                <img className='profile-img' src={pic} alt="residential architects near me" />
            </div>
        </div>
    </div>
}

export default AboutMe