import "./App.scss";
import ContactUs from "./pages/contact-us/contact-us";
import AboutUs from "./pages/about-us/about-us";
import Home from "./pages/home/home";
import Project from "./pages/project/project";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="projects" element={<Project />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
