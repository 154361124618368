import './footer.scss';
import insta from '../../assets/icons/instagram.svg'
import facebook from '../../assets/icons/Faceebook.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'
import linkedin from '../../assets/icons/Linkedin.svg'
import Tweeter from '../../assets/icons/Tweeter.svg'
import Telegram from '../../assets/icons/teligram.svg'


const Footer = () => {
    const socialServices = [
        {
            icon: insta,
            title: 'Instagram',
            link:'https://www.instagram.com/theheeraarchitects/'
        },
        {
            icon: facebook,
            title: 'Facebook',
            link:'https://www.facebook.com/shivam.chouksey.58'
        }, {
            icon: whatsapp,
            title: "What'sApp",
            link:'https://web.whatsapp.com/send?phone=919584695529'
        }, {
            icon: linkedin,
            title: 'Linkedin',
            link:'https://www.linkedin.com/in/shivam-ar8'
            
        }, {
            icon: Tweeter,
            title: 'Twitter',
            link:''
        },
        {
            icon: Telegram,
            title: 'Telegram',
            link:'https://t.me/@Architectshivam'
        },
    ]
    const openLink =(link)=>{
        if(link)
        window.open(link,"_blank")
    }
    return (
        <footer className='footer'>
            <div className='d-flex footer-content common-padding flex-column flex-md-row'>
                <div className='head'>
                    <div className='lets-friend'>
                        LET’S BE FRIENDS
                    </div>
                    <div className='sub-text'>
                        We'd love to hear from you! Please reach out at your convenience so we can discuss your project.
                    </div>
                </div>
                <div className='time-box'>
                    <div className='text'>
                        WORKING TIME
                    </div>
                    <div className='time'>
                        Monday - Sunday: 8am - 5pm
                    </div>
                </div>

            </div>
            <div className='social-icons d-flex common-padding flex-wrap'>
                {
                    socialServices.map((ele,ind) => (
                        <div onClick={()=>openLink(ele.link)} className={`${ind%2 === 0 ? 'mt-70':''} social-box d-flex flex-column mt-md`}>
                            <img className='social-img' src={ele.icon} alt="cheap architect near me" />
                            <span className='social-text'>{ele.title}</span>
                        </div>
                    ))
                }

            </div>
            <div className='right-reserved common-padding'>Copyright © 2021 Theheeraarchitects - All Rights Reserved.</div>
        </footer>
    )
}

export default Footer;