import Navbar from "../../components/navbar/navbar";
import About from "../../components/about/about";
import Footer from "../../components/footer/footer";

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <About />
            <Footer />
        </div>
    )
}
export default AboutUs;