import Navbar from "../../components/navbar/navbar";
import background from '../../assets/images/Backgroundbig.png'
import './home.scss'
import bannerDown from '../../assets/images/banner-down.png'
import AboutMe from "../../components/about-me/aboutMe";
import OurProjects from "../../components/our-projects/ourProjects";
import Footer from "../../components/footer/footer";
const Home = () => {
    return (
        <div>
            <section className='section-1'>
                <Navbar />
                <div className='d-flex banner align-item-center justify-content-between align-items-center flex-md-row flex-column common-padding'>
                    <div className='banner-text d-flex flex-column '>
                        <h1 className='heading'>THE HEERA ARCHITECTS</h1>
                        <h3 className='sub-heading'>Crafting LifeStyle</h3>
                    </div>
                    <img className='house' src={background} alt="the heera architects" />
                </div>
                <img className='banner-down' src={bannerDown} alt="architecture firms near me" />
            </section>
            <AboutMe />
            <OurProjects />
            <Footer />
        </div>
    )
}
export default Home;