import { useState } from 'react'
import logo from '../../assets/images/logo.png'
import menu from '../../assets/icons/list.svg'
import closeWhite from '../../assets/icons/close_white.svg'
import './navbar.scss'
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const redirect = (link) => {
        if (link)
            navigate(link)
    }

    return (
        <>
            <header className='header-main d-flex align-items-center justify-content-between common-padding'>
                <Link to="/"><img className='logo-nav' src={logo} alt="theHeeraArchitects" /></Link>
                <div className='d-none d-md-block'>
                    <div className='d-flex'>
                        <div></div>
                        <Link className={`header-text cool-link ${pathname === '/' ? 'activated' : ''}`} to="/">Home</Link>
                        <Link className={`header-text cool-link ${pathname === '/aboutus' ? 'activated' : ''}`} to="/aboutus">About Us</Link>
                        <Link className={`header-text cool-link ${pathname === '/projects' ? 'activated' : ''}`} to="/projects">Projects</Link>
                        {/* <Link className='header-text cool-link' to="/invoices">Expertise/Services</Link> */}
                        <Link className={`header-text cool-link ${pathname === '/contactus' ? 'activated' : ''}`} to="/contactus">Contact Us</Link>
                    </div>
                </div>

                <img className='cursor-pointer d-block d-md-none' onClick={() => setIsOpen(true)} src={menu} alt="architects near me" />
            </header>
            <nav className={`${isOpen ? 'header-drop-open' : ''} mobile header-drop d-block d-md-none`}>
                <div className='d-flex align-items-center justify-content-between header-drop-nav'>
                    <img className='invisible logo-nav' src={logo} alt="theHeeraArchitects" />

                    <img className='cursor-pointer' onClick={() => setIsOpen(false)} src={closeWhite} alt="architects near me" />
                </div>

                <div className='d-flex flex-column align-items-center'>
                    <div className='drop-link cursor-pointer' onClick={() => redirect('/')}>Home</div>
                    <div className='drop-link cursor-pointer' onClick={() => redirect('/aboutus')}>About Us</div>
                    <div className='drop-link cursor-pointer' onClick={() => redirect('/projects')}>Projects</div>
                    {/* <div className='drop-link cursor-pointer' onClick={()=>redirect('/')}>Services</div> */}
                    <div className='drop-link cursor-pointer' onClick={() => redirect('/contactus')}>Contact Us</div>
                </div>
            </nav>
        </>
    )
}
export default Navbar;