import './ourProjects.scss';
import residential from '../../assets/images/residentials.webp'
import interior from '../../assets/images/interior.webp'
import commercial from '../../assets/images/commercial.webp'
import { Link } from "react-router-dom";

const OurProjects = () => {
    const projects = [
        {
            title: 'Residentials',
            link: '',
            cssClass: {
                mainClass: 'align-items-start align-items-md-center',
                boxClass: 'align-items-start box-right align-items-md-end'
            },
            img:residential
        },
        {
            title: 'Interiors',
            link: '',
            cssClass: {
                mainClass: 'flex-md-row-reverse align-items-start align-items-md-center justify-content-between',
                boxClass: 'align-items-start'
            },
            img:interior

        },
        {
            title: 'Commercials',
            link: '',
            cssClass: {
                mainClass: 'align-items-start align-items-md-center',
                boxClass: 'align-items-start box-right align-items-md-end'
            },
            img:commercial
        }
    ]
    return (
        <div className='our-projects common-padding common-margin-top'>
            <h3 className='common-heading mb-5'>Our Projects</h3>
            <div className='content'>
                {
                    projects.map((project) => (
                        <div className={`d-flex flex-column box-margin flex-md-row ${project.cssClass.mainClass} `}>
                            <img className='picture' src={project.img} alt="architectural design firms near me" />
                            <div className={`box-2 d-flex flex-column ${project.cssClass.boxClass}`}>
                                <h1 className='title'>{project.title}</h1>
                                <Link className="view-button" to="/projects">View More</Link>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
export default OurProjects;