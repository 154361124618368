import pic from '../../assets/images/shivam-bhai-profile.jpeg';
import './about.scss';
const About = () => {
    return (
        <div class="about common-padding">
            <div class="heading d-flex justify-content-center justify-content-sm-start">
                ABOUT US
            </div>
            <div class="content d-flex flex-column-reverse flex-sm-row justify-content-sm-between align-items-start">
                <div class="content-word">
                    <div class="article-1">
                        Our team is a Bhopal-based architectural firm. Founded by <span class="name">AR.SHIVAM CHOUKSEY</span>, who believes in tradition and conserving the essence of a place, and building a sustainable life. All projects incorporate a unique identity and functional solutions. The firm’s big picture is to establish designs that can contribute to humankind and inexorably, the earth.
                    </div>
                    <div class="article-2 justify-content-center">
                        <div class="article-2-heading">
                            Design Philosophy
                        </div>
                        <div class="article-2-content">
                            <div>
                                The Heera architects believe in pragmatic and free-flowing design. The uniqueness and the concept of sustainability is the prior approach towards designing the spaces. We process with an ideology to serve people and the environment. The core of the design leads through the best level of functioning, engineering, and quality of details. Yes, The firm worships detail and the client's comfort.
                            </div>
                            <div>
                                we craft the emotions of an individual and deliver the key to the remarkable design.
                            </div>
                        </div>
                    </div>
                </div>
                <img class="content-img w-sm-100" src={pic} alt="residential architects near me"></img>
            </div>
        </div>
    )
}
export default About